import { ApolloProvider } from '@apollo/react-hooks';
import DateFnsUtils from '@date-io/date-fns';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import MuiThemeProvider from '@material-ui/styles/ThemeProvider';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import 'date-fns';
import frLocale from 'date-fns/locale/fr';
import React, { FC, Suspense, useEffect } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import parse from 'url-parse';
import './App.css';
import { load } from './common/Segment.io/analytics';
import TunnelAnalytics from './common/Segment.io/TunnelDom/TunnelAnalytics';
import Impersonation from './Components/Authentication/Impersonation';
import ContextProvider from './Components/Context';
import GlobalSnackBar from './Components/CustomComponents/GlobalSnackBar';
import ErrorBoundary from './Components/ErrorBoundary';
import MainLoadingDigidom from './Components/Loading/MainLoadingDigidom';
import apolloClient from './config/apolloClient';
import config from './config/config';
import { ERouteOption, ERoutePrincipal } from './Interface/Dashboard/CategoryInterface';
import customHistory from './Services/history';
import { themeBV } from './Theme/MyTheme';
import lazyRetry from './Utils/retry';
import TagManager from 'react-gtm-module';
import fr from 'date-fns/locale/fr';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import DigidomCgv from './Components/DigidomCgv';

const tagManagerArgs = {
  gtmId: config.googleTagManager || '',
  dataLayerName: 'ga4Datalayer',
};

TagManager.initialize(tagManagerArgs);

const Home = lazyRetry(() => import('./Components/Home'));
const Login = lazyRetry(() => import('./Components/Authentication/Login'));
const ForgotPassword = lazyRetry(() => import('./Components/Authentication/ForgotPassword'));
const LogOut = lazyRetry(() => import('./Components/Authentication/LogOut'));

const Tunnel = lazyRetry(() => import('./Components/Tunnel'));
const GoCardLessValidation = lazyRetry(() =>
  import('./Components/Authentication/GoCardLessValidation'),
);
const ViewDocument = lazyRetry(() => import('./Components/ViewDocument'));
const CustomerSummary = lazyRetry(() => import('./Components/CustomerSummary'));

let stripePromise: Promise<Stripe | null>;
export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(config.stripePublicAPIKey!);
  }
  return stripePromise;
};

setDefaultOptions({ locale: fr });

export const tunnelAnalytics = new TunnelAnalytics();

declare global {
  interface Window {
    $crisp: any;
    CRISP_WEBSITE_ID: string;
    __gCrWeb: any;
  }
}

const App: FC = () => {
  useEffect(() => {
    // crisp
    window.$crisp = [];
    window.$crisp.push(['safe', true]);
    window.CRISP_WEBSITE_ID = config.crispWebSiteId!;
    (() => {
      const d = document;
      const s = d.createElement('script');
      s.src = 'https://client.crisp.chat/l.js';
      s.async = true;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();

    window.ga4Datalayer = window.ga4Datalayer || [];

    if (!window['__gCrWeb']) window['__gCrWeb'] = {};
  }, []);

  useEffect(() => {
    load();
    const url = parse(window.location.href, true);
    const query = url.query;
    const { utm_source, utm_campaign, utm_medium, utm_content, utm_term } = query;
    if (utm_source) {
      localStorage.setItem('utm_source', utm_source);
    }
    if (utm_campaign) {
      localStorage.setItem('utm_campaign', utm_campaign);
    }
    if (utm_medium) {
      localStorage.setItem('utm_medium', utm_medium);
    }
    if (utm_content) {
      localStorage.setItem('utm_content', utm_content);
    }
    if (utm_term) {
      localStorage.setItem('utm_term', utm_term);
    }

    // track vu
    tunnelAnalytics.trackVu();
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <ErrorBoundary>
        <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
          <ContextProvider>
            <MuiThemeProvider theme={themeBV}>
              <GlobalSnackBar />
              <Router history={customHistory}>
                <Switch>
                  <Route path={`/${ERoutePrincipal.DIGIDOM_CGV}`} render={() => <DigidomCgv />} />
                  <Route
                    path="/auth/login"
                    render={() => (
                      <Suspense fallback={<MainLoadingDigidom />}>
                        <Login />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/auth/logout"
                    render={() => (
                      <Suspense fallback={<MainLoadingDigidom />}>
                        <LogOut />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/auth/forgot-password"
                    render={() => (
                      <Suspense fallback={<MainLoadingDigidom />}>
                        <ForgotPassword />
                      </Suspense>
                    )}
                  />
                  <Route exact={true} path={`/${ERoutePrincipal.TUNNEL}/:tunnel?/:organizationID?`}>
                    <Suspense fallback={<MainLoadingDigidom />}>
                      <Tunnel />
                    </Suspense>
                  </Route>

                  <Route
                    path="/validation-gc/:action/:source?"
                    render={() => (
                      <Suspense fallback={<MainLoadingDigidom />}>
                        <GoCardLessValidation />
                      </Suspense>
                    )}
                  />
                  <Route
                    path={`/${ERouteOption.CUSTOMER_SUMMARY}/:invoiceToken`}
                    render={() => (
                      <Suspense fallback={<MainLoadingDigidom />}>
                        <CustomerSummary />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/doc/:cryptedLink"
                    render={() => (
                      <Suspense fallback={<MainLoadingDigidom />}>
                        <ViewDocument />
                      </Suspense>
                    )}
                  />
                  <Route
                    path="/:navigation/:detail?"
                    render={() => (
                      <Suspense fallback={<MainLoadingDigidom />}>
                        <Home />
                      </Suspense>
                    )}
                  />

                  <Route path="/" render={() => <Impersonation />} />
                </Switch>
              </Router>
            </MuiThemeProvider>
          </ContextProvider>
        </MuiPickersUtilsProvider>
      </ErrorBoundary>
    </ApolloProvider>
  );
};

export default App;
