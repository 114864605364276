import { useQuery } from '@apollo/react-hooks';
import queryString from 'querystring';
import React, { FC, useEffect } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { QUERY_GET_USER_By_MAIL } from '../../../graphql/Authentication/query';
import {
  ERouteOption,
  ERoutePrincipal,
  ERouteTunnel,
} from '../../../Interface/Dashboard/CategoryInterface';
import { UserInterface } from '../../../Interface/UserInterface';
import AuthService from '../../../Services/AuthService';
import { toString } from '../../../Services/Calcul';
import MainLoadingDigidom from '../../Loading/MainLoadingDigidom';
import { RefreshTokenAndUser } from '../../../Services/RefreshTokenAndUser';
import { encryptText } from '../../../Utils/encryption';
import config from '../../../config/config';

// to account : https://app.digidom.pro/?org=ID_ORGANIZATION&mailParam=account/info
// to forward address : https://app.digidom.pro/?org=ID_ORGANIZATION&mailParam=account/forward-address
// to mandat : https://app.digidom.pro/?org=ID_ORGANIZATION&mailParam=account/mandate
// to letter: https://app.digidom.pro/?org=ID_ORGANIZATION&mailParam=letter
// to invoices: https://app.digidom.pro/?org=ID_ORGANIZATION&mailParam=document/invoices
// to documents: https://app.digidom.pro/?org=ID_ORGANIZATION&mailParam=document/documents
// to offer: https://app.digidom.pro/?org=ID_ORGANIZATION&mailParam=account/?expanded=OFFER
// to profil(rib): https://app.digidom.pro/?org=ID_ORGANIZATION&mailParam=account/?expanded=PROFIL

const Impersonation: FC<RouteComponentProps> = props => {
  const {
    location: { search },
  } = props;

  const queries = queryString.parse(search.substr(1, search.length));

  const {
    switch_user,
    user: userId,
    token: tokenFromURL,
    org: organizationId,
    edit,
    edit_immat: editImmatStatus,
    mailParam,
    isSwitch,
    to,
    return_link,
  } = queries;

  const token = AuthService.getAccessToken();

  const prescriberId = AuthService.getIsPrescriber();

  useEffect(() => {
    // restore skip payment if not edit immat
    if (!editImmatStatus && switch_user) {
      AuthService.setShowBeforePayment('');
    }
    // Set all query string impersonation in local storage
    if (!isSwitch) {
      const editType = edit ? 'DOM' : editImmatStatus ? 'IMMAT' : '';
      AuthService.setSwitchUser(toString(switch_user));
      AuthService.setOrganizationIDByAdmin(toString(organizationId));
      AuthService.setIsEditOrganization(editType);
      AuthService.setToPage(toString(to));
      AuthService.setAdminLink(toString(return_link));
    }

    // If no impersonation, connect with token and userId
    if (tokenFromURL && userId && !switch_user) {
      AuthService.setIsFromBV('');
      AuthService.setAccessToken(tokenFromURL.toString());
      AuthService.setUserId(userId.toString());
      window.history.replaceState(null, '', `/${ERouteOption.LETTER}`);
      window.location.reload();
    }

    // If impersonation reload page
    if (switch_user && !userId) {
      window.history.replaceState(null, '', '/?isSwitch=true');
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    if (!prescriberId || +prescriberId !== +organizationId) {
      AuthService.setIsPrescriber('');
    }
  }, [prescriberId]);

  // If impersonation
  if (isSwitch) {
    return <GetUserBySwitchUser />;
  }

  if (!token && !tokenFromURL) {
    if (!mailParam) {
      return <Redirect to="/auth/login" />;
    }
    return <Redirect to={`/auth/login/?origin=${mailParam}`} />;
  } else {
    if (mailParam) {
      return <Redirect to={`/${mailParam}`} />;
    }
    return <Redirect to={`/${ERouteOption.LETTER}`} />;
  }
};

export default withRouter(Impersonation);

const GetUserBySwitchUser: FC = () => {
  const to = AuthService.getToPage();
  const switchUser = AuthService.getSwitchUser();
  const token = AuthService.getAccessToken();
  const { data, loading, error } = useQuery<{ getSwitchUserByMail: UserInterface }>(
    QUERY_GET_USER_By_MAIL,
    { variables: { mail: switchUser }, skip: !token },
  );

  if (!token) {
    return <Redirect to="/auth/login" />;
  }

  if (loading) {
    return <MainLoadingDigidom />;
  }

  if (error) {
    AuthService.clearToken();
    return <Redirect to="/auth/login" />;
  }

  if (!data || !data.getSwitchUserByMail) {
    return null;
  }

  const userData = data.getSwitchUserByMail;

  AuthService.setSwitchUserId(userData.id!);

  const edit = AuthService.getIsEditOrganization();

  const orgID = AuthService.getOrganizationIDByAdmin();

  if (edit && orgID) {
    AuthService.setShowBeforePayment('');
    AuthService.setOrganizationIDByAdmin('');
    AuthService.setIsEditOrganization('');
    const goingToType = edit.trim() === 'DOM' ? 'edit=true' : 'edit_immat=true';

    // const route = `/${ERoutePrincipal.TUNNEL}/${ERouteTunnel.MY_CONTRACT}/${orgID}?${goingToType}`;
    const { user, refreshToken, switchUser } = RefreshTokenAndUser.getTRefreshTokenAndUser();

    const { iv, encryptedData } = encryptText(
      `${(user && user.email) || (refreshToken && refreshToken.username)}&${
        refreshToken.refreshToken
      }`,
    );

    // return <Redirect to={route} />;
    window.location.href = `${config.tunnelUrl}?org=${orgID}&iv=${iv}&auth=${encryptedData}&switch_user=${switchUser}&${goingToType}`;
  }

  const goTO = to ? to : ERouteOption.LETTER;
  if (to) {
    AuthService.setToPage('');
  }

  return <Redirect to={`/${goTO}`} />;
};
