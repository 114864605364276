import React, { FC, useState } from 'react';
import { IsPrescriberContext } from './IsPrescriberContext';

const IsPrescriberContextProvider: FC = ({ children }) => {
  const [isPrescriber, setIsPrescriber] = useState(false);

  const handleSetPrescriber = (prescriberState: boolean) => {
    setIsPrescriber(prescriberState);
  };
  return (
    <IsPrescriberContext.Provider value={{ isPrescriber, setIsPrescriber: handleSetPrescriber }}>
      {children}
    </IsPrescriberContext.Provider>
  );
};

export default IsPrescriberContextProvider;
