import config from '../config/config';
import CallbackFunction from '../Interface/CallbackFunction';
import { ERouteTunnel } from '../Interface/Dashboard/CategoryInterface';
import { DocumentType } from '../Interface/Dashboard/Document';
import AuthService from './AuthService';
import { checkLimitFileSize } from './Calcul';
import isAdmin from './isAdmin';

const headers = (token?: string): any => {
  // bug CORS

  // const switchUser = AuthService.getSwitchUser();
  // if (switchUser) {
  //   return {
  //     Authorization: `Bearer ${AuthService.getAccessToken()}`,
  //     'Content-Type': 'multipart/form-data',
  //     'x-switch-user': switchUser,
  //   };
  // }
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    };
  }
  return {
    Authorization: `Bearer ${AuthService.getAccessToken()}`,
    'Content-Type': 'multipart/form-data',
  };
};

interface UploadFileParams {
  file: File;
  name: string;
  type: DocumentType;
  status: string;
  organizationId?: number;
  userId?: number;
  lrID?: number;
  personId?: string;
}

export const UploadFileToApiDigidom = (params: UploadFileParams): Promise<any> => {
  const { file, name, status, type, lrID, organizationId, personId, userId } = params;

  return new Promise(async (resolve, reject) => {
    if (!checkLimitFileSize(file)) {
      reject({ message: 'Le ficher doit être inférieur à 5 Mo', type: 'ERROR' });
    } else if (
      file.type !== 'image/jpg' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/png' &&
      file.type !== 'application/pdf'
    ) {
      reject({ message: "Le ficher n'est pas prise en charge", type: 'ERROR' });
    } else {
      const formData = new FormData();

      formData.append('documentFile', file, file.name);
      if (organizationId) {
        const organization = `/v1/organizations/${organizationId}`;
        formData.append('organization', organization);
      }
      if (userId) {
        const user = `/v1/users/${userId}`;
        formData.append('user', user);
      }

      if (lrID) {
        const legalRepresentative = `/v1/legal_representatives/${lrID}`;
        formData.append('legalRepresentative', legalRepresentative);
      }

      if (personId) {
        if (isNaN(+personId)) {
          formData.append('person', personId);
        } else {
          formData.append('person', `/v1/people/${personId}`);
        }
      }

      formData.append('type', type);
      formData.append('status', status);
      formData.append('name', name);

      const options: RequestInit = {
        method: 'POST',
        headers: headers(),
        body: formData,
      };

      delete (options.headers as any)['Content-Type'];

      fetch(config.apiSymphonyDigidom + '/documents', options)
        .then(response => response.json().then(document => resolve(document)))
        .catch(() => reject({ message: "Impossible d'uploader le fichier", type: 'ERROR' }));
    }
  });
};

export const downloadFile = (link: string, name: string, setLoading: CallbackFunction<boolean>) => {
  setLoading(true);
  fetch(`${config.corsDigidom}?url=${link}`, { method: 'GET', headers: headers() })
    .then(response => response.blob())
    .then(blob => {
      const a = document.createElement('a');
      a.download = `${name}`;
      a.href = window.URL.createObjectURL(blob);
      a.click();
      alert('Téléchargement terminé');
    })
    .catch(error => console.log(error))
    .then(() => setLoading(false));
};

export const segmentTrack = (input: { email?: string; data: any; token?: string }) => {
  const { email, data, token } = input;
  let url = `${config.apiSymphonyDigidom}/segment/track`;
  if (email) {
    url = `${config.apiSymphonyDigidom}/segment/track?email=${email}`;
  }

  return fetch(url, {
    method: 'POST',
    headers: headers(token),
    body: JSON.stringify(data),
  });
};

export const segmentIdentifyScanEnveloppe = (input: { email?: string; organizationId: string }) => {
  let url = `${config.apiSymphonyDigidom}/segment/identify-organization/${input.organizationId}`;
  if (input.email) {
    url = `${config.apiSymphonyDigidom}/segment/identify-organization/${input.organizationId}?email=${input.email}`;
  }

  return fetch(url, {
    method: 'POST',
    headers: headers(),
  });
};

export const segmentIdentify = (data: any) => {
  const url = `${config.apiSymphonyDigidom}/segment/identify`;

  return fetch(url, {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(data),
  });
};

export const sendHistoryNavigationTunnel = (
  organizationId: number,
  historyNavTunnel: ERouteTunnel,
) => {
  const query = `mutation sendHistoryNavigationTunnel($organizationId: ID, $data: OrganizationInput){
    createOrEditOrganizationTunnel(organizationId: $organizationId, data: $data){
      id
      historyNavTunnel
    }
  }`;

  const body = {
    operationName: 'sendHistoryNavigationTunnel',
    variables: {
      organizationId: `${organizationId}`,
      data: {
        historyNavTunnel: `${historyNavTunnel}`,
      },
    },
    query,
  };

  const url = `${config.serverUri}`;

  const headersTunnel: {
    Authorization: string;
    'Content-Type': string;
    'x-switch-user'?: string | null;
  } = {
    Authorization: `Bearer ${AuthService.getAccessToken()}`,
    'Content-Type': 'application/json',
  };

  if (isAdmin()) {
    headersTunnel['x-switch-user'] = AuthService.getSwitchUser();
  }

  return fetch(url, {
    method: 'POST',
    headers: headersTunnel as any,
    body: JSON.stringify(body),
  });
};
