import React, { FC } from 'react';
import RefetchContextProvider from '../Tunnel/Context/RefetchContext';
import CheckValidationContextProvider from './CheckValidationContext/CheckValidationContextProvider';
import IsPrescriberContextProvider from './IsPrescriberContext';
import RouteContextProvider from './RouteContext';
import ShowCrispContextProvider from './ShowCrispContextProvider';
import ShowZooContextProvider from './ShowZooContext';
import IsInRiskCountryContextProvider from './IsInRiskCountryContext/IsInRiskCountryContextProvider';

const ContextProvider: FC = ({ children }) => {
  return (
    <RefetchContextProvider>
      <IsInRiskCountryContextProvider>
        <IsPrescriberContextProvider>
          <CheckValidationContextProvider>
            <ShowCrispContextProvider>
              <ShowZooContextProvider>
                <RouteContextProvider>{children}</RouteContextProvider>
              </ShowZooContextProvider>
            </ShowCrispContextProvider>
          </CheckValidationContextProvider>
        </IsPrescriberContextProvider>
      </IsInRiskCountryContextProvider>
    </RefetchContextProvider>
  );
};

export default ContextProvider;
